import { graphql, PageProps } from "gatsby";
import React, { FC, FormEvent, useState } from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import * as styles from '../css/detail.module.css'
import { ServiceCard } from "../components/service-card";
import { PageTitle } from "../components/page-title";
import { ContainerCenter } from "../components/container-center";
import { SearchForm } from "../components/search-form";
import { toKana, toRomaji } from 'wanakana';

function zenkaku2Hankaku(str: string) {
  return str.replace(/[A-Za-z0-9]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
  });
}

const detail: FC<PageProps<GatsbyTypes.PostByIdQuery>> = ({ data, location })=>{
  const title=data.post?.title||'nodata';

  const [searchKeyword, updateSearchKeyword] = useState('');

  const onInput = (e: FormEvent<HTMLInputElement>) => {
    let val = e.currentTarget.value;
    val = toRomaji(val);
    val = zenkaku2Hankaku(val);
    console.log(val);
    updateSearchKeyword(val);
  };

  const regexp = new RegExp(searchKeyword, 'i');

  const filteredList = data.post?.services?.filter(item => {
    if(item?.title) {
      let val = item.title;
      val = toRomaji(val);
      val = zenkaku2Hankaku(val);
  
      return regexp.test(val)
    }
  });

  return (
    <Layout location={location} title={title}>
      <Seo title={title} lang="ja" description="description" />
      <PageTitle pageTitle={title} discription="コンテンツ詳細" />
      
      <ContainerCenter>
        <div className={styles.serchContainer}>
          <SearchForm placeholder="キーワード検索" onInput={onInput}></SearchForm>
          <div className={styles.serchDiscription}>
            気になる商品のアフィリエイトリンクをクリックすると、サイトに遷移できます。
          </div>
        </div>
      </ContainerCenter>

      <ContainerCenter>
        <div className={styles.cardContainer}>
          {filteredList?.map((item, key) => {
            return (
              <ServiceCard title={item?.title||'nodata'} affliateDomElement={item?.affliateDomElement||'nodata'} officialSiteUrl={'nodata'}></ServiceCard>
            );
          })}
        </div>
      </ContainerCenter>
    </Layout>
  )
}

export default detail;

export const pageQuery = graphql`
query PostById($id: String!) {
  site {
    siteMetadata {
      title
    }
  }
  post: servicePageJson(id: {eq: $id}) {
    id
    slug
    title
    services {
      affliateDomElement
      title
    }
  }
}`