import * as React from 'react';

export interface Props {
  placeholder?: string
  className?: string
  id?: string
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void
}

export const SearchForm: React.FC<Props> = ({ className, placeholder, id, onInput }) => (
  <input name="search" type="text" placeholder={placeholder} id={id} onInput={onInput} />
)