import * as React from "react"
import { FC } from "react"
import { Button } from "../components/button"

import * as styles from '../css/service-card.module.css'

type CardProps = {
    title: string,
    officialSiteUrl: string,
    affliateDomElement: string
} 

export const ServiceCard: FC<CardProps>= ({ title, officialSiteUrl, affliateDomElement }) => {
  const decodedURI = affliateDomElement.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  return (

    <div className={styles.container}>
      <h3 className={styles.titleBox}>{title}</h3>
      <div className={styles.content}>
        <div className={styles.affiImg} dangerouslySetInnerHTML={{__html: decodedURI}}>
        </div>
        {/* <Button link={officialSiteUrl} text ="公式サイトはこちら" /> */}
      </div>
    </div>
  )
}

